/**
 * Created by evgenijsenkevic on 19.09.2024
 */

var OpenFogView = cc.Node.extend({
    ctor: function (component, unitView) {
        this._super();

        this.unitView = unitView;

        if (!cleverapps.config.debugMode) {
            return;
        }

        var label = cleverapps.UI.generateOnlyText(component.fog, cleverapps.styles.FONTS.WHITE_TEXT);
        label.setPositionRound({ align: "center" }, { align: "top", dy: 40 });
        unitView.addChild(label);
    }
});